import { AxiosError, AxiosResponse } from 'axios'
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query'

import * as requests from './requests'
import { TLeadersListItem, TParams, TStoresListItem, TWalletsManagementDetails } from './types'
import { ResponseError, ResponseSuccess } from '../../types'

export const KEY_LEADERS_LIST_QUERY = 'getLeadersList'

export const KEY_STORES_LIST_QUERY = 'getStoresList'

export const KEY_WALLETS_MANAGEMENT_DETAILS_QUERY = 'getWalletsManagementDetails'

export const useGetLeadersListInfinityQuery = ({ enabled = true, ...params }: TParams) =>
  useInfiniteQuery<AxiosResponse<ResponseSuccess<TLeadersListItem[]>>, AxiosError<ResponseError>>(
    [KEY_LEADERS_LIST_QUERY, params],
    // @ts-ignore
    ({ pageParam }) => requests.getLeadersList({ ...params, currentPage: pageParam }),
    {
      enabled,
      getNextPageParam: (lastPage) => {
        if (
          lastPage.data.metadata.pagination.current_page <
          lastPage.data.metadata.pagination.last_page
        ) {
          return lastPage.data.metadata.pagination.current_page + 1
        }

        if (
          lastPage.data.metadata.pagination.current_page ===
          lastPage.data.metadata.pagination.last_page
        ) {
          return false
        }
      }
    }
  )

export const useGetStoresListInfinityQuery = ({ enabled = true, ...params }: TParams) =>
  useInfiniteQuery<AxiosResponse<ResponseSuccess<TStoresListItem[]>>, AxiosError<ResponseError>>(
    [KEY_STORES_LIST_QUERY, params],
    // @ts-ignore
    ({ pageParam }) => requests.getStoresList({ ...params, currentPage: pageParam }),
    {
      enabled,
      getNextPageParam: (lastPage) => {
        if (
          lastPage.data.metadata.pagination.current_page <
          lastPage.data.metadata.pagination.last_page
        ) {
          return lastPage.data.metadata.pagination.current_page + 1
        }

        if (
          lastPage.data.metadata.pagination.current_page ===
          lastPage.data.metadata.pagination.last_page
        ) {
          return false
        }
      }
    }
  )

export const useGetWalletsManagementDetailsQuery = (leader_std_code: number) => {
  const queryClient = useQueryClient()

  return useQuery<
    AxiosResponse<ResponseSuccess<TWalletsManagementDetails>>,
    AxiosError<ResponseError>
  >(
    [`${KEY_WALLETS_MANAGEMENT_DETAILS_QUERY}.${leader_std_code}`],
    () => requests.getWalletsManagementDetails(leader_std_code),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(KEY_WALLETS_MANAGEMENT_DETAILS_QUERY)
      }
    }
  )
}

export const useConcludeWalletsManagementMutation = () =>
  useMutation<
    AxiosResponse<ResponseSuccess<Pick<TParams, 'leader_std_code'>>>,
    AxiosError<ResponseError>,
    Pick<TParams, 'leader_std_code'>
  >(requests.concludeWalletsManagement)
