import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { LeadersListScreenComponent } from './LeadersListScreen.types'
import { useLeadersListScreen } from './useLeadersListScreen'

const LeadersListScreenDesktop = lazy(() => import('./LeadersListScreen.desktop'))

const LeadersListScreenMobile = lazy(() => import('./LeadersListScreen.mobile'))

export const LeadersListScreen: LeadersListScreenComponent = ({ navigation, route }) => {
  const props = useLeadersListScreen({ navigation, route })

  const Screen = props.isMobile ? LeadersListScreenMobile : LeadersListScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...props} />
    </Suspense>
  )
}
