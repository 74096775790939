import { TLeadersListItem, TParams, TStoresListItem, TWalletsManagementDetails } from './types'
import client from '../../client'
import { ResponseSuccess } from '../../types'

const USE_MOCK = true

const mockAxiosResponse = (data: any) => ({
  data,
  status: 200,
  statusText: 'OK',
  headers: {},
  config: {},
  request: {}
})

export const mockedStoresListResponse = {
  serviceSuccess: true,
  hasErrors: false,
  errors: [],
  metadata: {
    pagination: {
      total: 10,
      per_page: 10,
      current_page: 1,
      last_page: 1,
      first_page: 1,
      first_page_url: '/?page=1',
      last_page_url: '/?page=1',
      next_page_url: null,
      previous_page_url: null
    },
    runtime: 35,
    requestId: 'clujqrna8000908jtbk9d45ub'
  },
  data: Array.from({ length: 10 }, (_, index) => ({
    store_name: `Grupo Fiat ${index + 1}`,
    store_tab_number: `654${100 + index}`,
    segment: `Motos (VarejoG)`,
    store_image_file_name: `https://randomuser.me/api/portraits/men/${index}.jpg`
  })),
  status: 200
}

export const mockedLeadersListResponse = {
  serviceSuccess: true,
  hasErrors: false,
  errors: [],
  metadata: {
    pagination: {
      total: 10,
      per_page: 10,
      current_page: 1,
      last_page: 1,
      first_page: 1,
      first_page_url: '/?page=1',
      last_page_url: '/?page=1',
      next_page_url: null,
      previous_page_url: null
    },
    runtime: 35,
    requestId: 'clujqrna8000908jtbk9d45ub'
  },
  data: Array.from({ length: 10 }, (_, index) => ({
    user_name: `Funcionário ${index + 1}`,
    occupation: 'Gerente de Vendas',
    user_std_code: `654${100 + index}`,
    qtd_stores: Math.floor(Math.random() * 100) + 1,
    profile_image_file_name: `https://randomuser.me/api/portraits/men/${index}.jpg`
  })),
  status: 200
}

export const mockedWalletsManagementDetailsResponse = {
  serviceSuccess: true,
  hasErrors: false,
  errors: [],
  metadata: {
    runtime: 27,
    requestId: 'clujqt0b6000a08jt5ct8ffjl'
  },
  data: {
    is_open: true,
    reactivated: true,
    openingDate: '01/09/2022',
    closingDate: '10/09/2022',
    specialists: {
      total: 315,
      assigned: 154
    },
    tabs: {
      total: 456,
      assigned: 452
    }
  },
  status: 200
}

export const getLeadersList = async (params: TParams) => {
  if (USE_MOCK) {
    return Promise.resolve(mockAxiosResponse(mockedLeadersListResponse))
  } else {
    await client.get<ResponseSuccess<TLeadersListItem[]>>(
      `v1/wallets/management/leaders/${params.leader_std_code}/staff`,
      {
        params: {
          per_page: params?.per_page ?? 10,
          page: params?.page ?? 1,
          order_by: params.order_by ?? undefined,
          order_by_direction: params.order_by_direction ?? 'desc',
          ...params
        }
      }
    )
  }
}

export const getStoresList = async (params: TParams) => {
  if (USE_MOCK) {
    return Promise.resolve(mockAxiosResponse(mockedStoresListResponse))
  } else {
    await client.get<ResponseSuccess<TStoresListItem[]>>(
      `v1/wallets/management/leaders/${params.leader_std_code}/stores`,
      {
        params: {
          per_page: params?.per_page ?? 10,
          page: params?.page ?? 1,
          filter_by: params?.filter_by ?? undefined,
          ...params
        }
      }
    )
  }
}

export const getWalletsManagementDetails = async (leader_std_code: number) => {
  if (USE_MOCK) {
    return Promise.resolve(mockAxiosResponse(mockedWalletsManagementDetailsResponse)) as any
  } else {
    await client.get<ResponseSuccess<TWalletsManagementDetails>>(
      `v1/wallets/management/leaders/${leader_std_code}`
    )
  }
}

export const concludeWalletsManagement = async ({ leader_std_code }: TParams) =>
  await client.patch<ResponseSuccess<Pick<TParams, 'leader_std_code'>>>(
    `v1/wallets/management/leaders/${leader_std_code}`,
    {
      leader_std_code
    }
  )
