import { FunctionComponent } from 'react'

import {
  TLeadersListItem,
  TStoresListItem,
  TWalletsManagementDetails
} from 'integration/resources/walletsManagement'
import { RootStackScreenComponentProps } from 'src/navigation'

export type LeadersListScreenComponent = FunctionComponent<LeadersListScreenProps>

export type LeadersListScreenProps = RootStackScreenComponentProps<'LeadersList'>

export type ResponsiveLeadersListScreenComponent = FunctionComponent<
  ReturnType<UseLeadersListScreen>
>

export type CardProps = TLeadersListItem

export enum Tab {
  Specialists,
  AssignedTabs,
  UnassignedTabs
}

export type TabButtonProps = {
  tab: Tab
  label: string
  currentTab: Tab
  onPress: (tab: Tab) => void
}

export type OrderOption = {
  id: 'asc' | 'desc'
  name: string
}

export type OrderField = {
  label: string
  options: OrderOption[]
}

export type OrderByButtonProps = {
  handleCheckboxChange: (field: string, direction: 'asc' | 'desc') => void
  handleClear: () => void
  handleApply: () => void
  selectedOrderBy: {
    field: string | undefined
    direction: 'asc' | 'desc' | undefined
  }
  orderByIsOpen: boolean
  orderByOnOpen: () => void
  orderByOnClose: () => void
}

export type UseLeadersListScreen = ({
  navigation,
  route
}: Pick<LeadersListScreenProps, 'navigation' | 'route'>) => {
  isMobile: boolean
  onSearch?(search: string): void
  search?: string
  onPressSearch?: () => void
  onPressSearchClear?: () => void
  listData?: TLeadersListItem[]
  listIsLoading: boolean
  storesData?: TStoresListItem[]
  storesListIsLoading: boolean
  detailsData?: TWalletsManagementDetails
  detailsIsLoading: boolean
  handleTabChange: (tab: Tab) => void
  currentTab: number
  concludeModalIsOpen: boolean
  concludeModalOnClose: () => void
  concludeModalOnOpen: () => void
  handleConclude: () => void
  concludeIsLoading: boolean
  handleCheckboxChange: (field: string, direction: 'asc' | 'desc') => void
  handleClear: () => void
  handleApply: () => void
  selectedOrderBy: {
    field: string | undefined
    direction: 'asc' | 'desc' | undefined
  }
  orderByIsOpen: boolean
  orderByOnOpen: () => void
  orderByOnClose: () => void
  calculateTotalStores: (leadersList: TLeadersListItem[]) => number
}
