import { useMemo } from 'react'

import { useGetHirePlusUrlQuery } from 'integration/resources/hirePlus'
import { useBreakpointValue } from 'native-base'

import { UseHirePlusScreen } from './HirePlusScreen.types'

export const useHirePlusScreen: UseHirePlusScreen = ({ navigation, route }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const { data: hirePlusData, isLoading } = useGetHirePlusUrlQuery()

  const iFrameUrl = useMemo(() => hirePlusData?.data.data.url, [hirePlusData?.data.data.url])

  return {
    isMobile,
    iFrameUrl,
    isLoading
  }
}
